import React from "react";

const Gift2 = () => {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10552_35258)">
        <path
          d="M11.4211 3.31579H10.1589C10.2226 3.13632 10.2632 2.93947 10.2632 2.73684C10.2632 1.77579 9.48737 1 8.52632 1C7.91842 1 7.39158 1.31263 7.07895 1.78158L6.78947 2.16947L6.5 1.77579C6.18737 1.31263 5.66053 1 5.05263 1C4.09158 1 3.31579 1.77579 3.31579 2.73684C3.31579 2.93947 3.35632 3.13632 3.42 3.31579H2.15789C1.51526 3.31579 1.00579 3.83105 1.00579 4.47368L1 10.8421C1 11.4847 1.51526 12 2.15789 12H11.4211C12.0637 12 12.5789 11.4847 12.5789 10.8421V4.47368C12.5789 3.83105 12.0637 3.31579 11.4211 3.31579ZM8.52632 2.15789C8.84474 2.15789 9.10526 2.41842 9.10526 2.73684C9.10526 3.05526 8.84474 3.31579 8.52632 3.31579C8.2079 3.31579 7.94737 3.05526 7.94737 2.73684C7.94737 2.41842 8.2079 2.15789 8.52632 2.15789ZM5.05263 2.15789C5.37105 2.15789 5.63158 2.41842 5.63158 2.73684C5.63158 3.05526 5.37105 3.31579 5.05263 3.31579C4.73421 3.31579 4.47368 3.05526 4.47368 2.73684C4.47368 2.41842 4.73421 2.15789 5.05263 2.15789ZM11.4211 10.8421H2.15789V9.68421H11.4211V10.8421ZM11.4211 7.94737H2.15789V5.05263C2.15789 4.73421 2.41842 4.47368 2.73684 4.47368H5.09895L4.24211 5.64316C4.05105 5.90368 4.10895 6.26842 4.36947 6.45368C4.62421 6.63895 4.98895 6.58105 5.17421 6.32632L6.78947 4.12632L8.40474 6.32632C8.59 6.58105 8.95474 6.63895 9.20947 6.45368C9.47 6.26842 9.5279 5.90368 9.33684 5.64316L8.48 4.47368H10.8421C11.1605 4.47368 11.4211 4.73421 11.4211 5.05263V7.94737Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_10552_35258">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Gift2;
